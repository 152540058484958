import axios from 'axios';
import { serialize } from 'object-to-formdata';

export async function getLoxoCandidateDetails({ candidateId, auth }) {
  try {
    const { data } = await axios.get(`https://loxo.co/api/cherrypicker/people/${candidateId}`, {
      headers: { Accept: 'application/json', Authorization: auth },
    });

    return data;
  } catch (e) {
    return {};
  }
}

export async function getLoxoCandidateEmployment({ candidateId, auth }) {
  try {
    const { data } = await axios.get(`https://loxo.co/api/cherrypicker/people/${candidateId}`, {
      headers: { Accept: 'application/json', Authorization: auth },
    });

    return data.job_profiles || [];
  } catch (e) {
    return [];
  }
}

export async function updateLoxoCandidateEmployment({ candidateId, auth, payload }) {
  try {
    const formData = serialize(payload, { indices: true });

    const config = {
      method: 'patch',
      url: `https://loxo.co/api/cherrypicker/people/${candidateId}`,
      headers: {
        Authorization: auth,
        Accept: 'application/json',
      },
      data: formData,
    };

    const response = await axios(config);

    // console.log(response.data);
  } catch (e) {
    console.log({ e });
  }
}

export async function getResumeFile({ candidateLoxoId, resumeId, auth }) {
  try {
    const res = await fetch(`https://loxo.co/api/cherrypicker/people/${candidateLoxoId}/resumes/${resumeId}/download`, {
      method: 'GET',
      headers: {
        Authorization: auth,
      },
    });

    const blob = await res.blob();

    const url = URL.createObjectURL(blob);

    return url;
  } catch (e) {
    console.log(e);
    return '';
  }
}
